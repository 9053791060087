<template>
  <v-row class="provider">
    <v-col cols="12" class="pa-0">
      <div class="title">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link to="/providers" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>{{ data.name }}
          </router-link>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <div v-else>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
          <div class="d-flex align-center">
            <div class="text-uppercase text-h5 font-weight-bold">Provider details</div>
            <div class="status ml-10" :class="data.enabled ? 'success' : 'error'">{{ data.enabled ? 'On' : 'Off' }}</div>
          </div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="data.name"
                  :error-messages="nameErrors"
                  label="Name"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
                <v-text-field
                  v-model="data.login"
                  :error-messages="loginErrors"
                  label="Login"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
                <v-text-field
                  v-model="data.password"
                  :error-messages="passwordErrors"
                  label="Password"
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show = !show"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-text-field>
                <v-text-field
                  v-model="data.host"
                  :error-messages="hostErrors"
                  label="Host/IP"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                >
                </v-text-field>
                <v-text-field
                  v-model="data.port"
                  :error-messages="portErrors"
                  label="Port"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pl-md-16">
                <v-checkbox v-model="data.enabled" color="success" class="mt-0">
                  <template v-slot:label>
                    <div class="d-flex flex-column">
                      <div class="font-weight-medium">Enabled</div>
                      <div class="f14 opasity--text">In account enabled, the gateway will bind to Provider</div>
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="data.tls" color="success" class="mt-2">
                  <template v-slot:label>
                    <div class="d-flex flex-column">
                      <div class="font-weight-medium">TLS connection</div>
                      <div class="f14 opasity--text">Connect to Provider using TLS</div>
                    </div>
                  </template>
                </v-checkbox>
                <v-checkbox v-model="data.tls_validation" color="success" class="mt-2">
                  <template v-slot:label>
                    <div class="d-flex flex-column">
                      <div class="font-weight-medium">Validate TLS certificate</div>
                      <div class="f14 opasity--text">Perform Provider TLS certificate validation. Disable in case Provider</div>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-sm-10 my-2"></v-divider>
            <div class="d-flex flex-wrap justify-sm-space-between justify-center">
              <v-btn @click="deleteModal = true" class="mt-sm-0 mt-2" outlined elevation="0" width="184" height="48" color="error">
                Delete
              </v-btn>
              <div class="text-md-rigth text-center">
                <v-btn class="mr-sm-5 mt-sm-0 mt-2" elevation="0" width="184" height="48" color="secondary" @click="update(false)">
                  Save
                </v-btn>
                <v-btn class="mt-sm-0 mt-2" elevation="0" width="184" height="48" color="primary" @click="update(true)">
                  Save and Apply
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Binds</div>
          <div class="px-sm-10 py-sm-5">
            <v-row class="mb-5">
              <v-col cols="12" md="6">
                <div class="text-h6 mb-3">Total send/receive rate</div>
                <div class="empty"></div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-h6 mb-3">Total sent/received</div>
                <div class="empty"></div>
              </v-col>
            </v-row>
            <div class="text-h6">Provider binds</div>
            <v-divider></v-divider>
            <v-data-table
              hide-default-footer
              fixed-header
              :headers="headersBinds"
              :items="binds.binds"
              item-key="id"
              :items-per-page="-1"
              :height="(binds.binds.length + 1) * 48 > 530 ? 530 : (binds.binds.length + 1) * 48"
            >
              <template v-slot:item.sent="{ item }">{{ item.sent | count(item.sent) }}/{{ item.received | count(item.received) }}</template>
              <template v-slot:item.send_rate="{ item }">{{ item.receive_rate }}/{{ item.send_rate }}</template>
            </v-data-table>
            <v-divider></v-divider>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Limits</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="data.limits.inbound_window"
                  :error-messages="inWinErrors"
                  label="Inbound window"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
                <v-text-field
                  v-model="data.limits.inbound_rate_limit"
                  :error-messages="inRateErrors"
                  label="Inbound rate limits m/s"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
                <v-text-field
                  v-model="data.limits.max_binds"
                  :error-messages="maxBindsErrors"
                  label="Max binds count"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="data.limits.outbound_window"
                  :error-messages="outWinErrors"
                  label="Outbound window"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
                <v-text-field
                  v-model="data.limits.outbound_rate_limit"
                  :error-messages="outRateErrors"
                  label="Outbound rate limits m/s"
                  filled
                  outlined
                  dense
                  required
                  color="input"
                  maxlength="50"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="my-sm-10 my-2"></v-divider>
            <div class="text-sm-right text-center">
              <v-btn class="mt-sm-0 mt-2 me-sm-5" elevation="0" width="184" height="48" color="secondary" @click="update(false)">
                Save
              </v-btn>
              <v-btn class="mt-sm-0 mt-2" elevation="0" width="184" height="48" color="primary" @click="update(true)">Save and Apply</v-btn>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5 mt-5">
          <div class="text-uppercase text-h5 font-weight-bold">Log Config</div>
          <div class="px-sm-10 py-sm-5">
            <v-row>
              <v-col cols="12" md="6">
                <div class="text-h6">Log Level</div>
                <div class="opasity--text">Please note, that more verbose log levels (Debug, Info) might reduce bind performance</div>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  class="mr-4"
                  v-model="data.log_level"
                  :items="logLevelList"
                  item-text="title"
                  item-value="name"
                  hide-details
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-divider class="my-sm-10 my-4"></v-divider>
            <div class="text-sm-right text-center">
              <v-btn class="mr-sm-5 mt-sm-0 mt-2" elevation="0" width="184" height="48" color="secondary" @click="update(false)">
                Save
              </v-btn>
              <v-btn class="mt-sm-0 mt-2" elevation="0" width="184" height="48" color="primary" @click="update(true)">Save and Apply</v-btn>
            </div>
          </div>
        </v-card>
        <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 mt-5 pb-md-10 pb-4">
          <div class="d-flex flex-wrap justify-space-between align-center pa-md-10 pb-md-4 pa-5 pb-3">
            <div class="text-uppercase text-h5 font-weight-bold">Logs</div>
            <div class="d-flex">
              <v-select
                class="mr-4"
                v-model="logLevel"
                :items="logLevelList"
                item-text="title"
                item-value="name"
                hide-details
                outlined
                dense
                @change="getLogs"
              ></v-select>
              <v-btn @click="copy" color="primary" outlined height="48" width="148"><v-icon left>mdi-file-multiple</v-icon>Copy</v-btn>
            </div>
          </div>
          <v-divider></v-divider>
          <v-simple-table class="p-table">
            <template v-slot:default>
              <tbody>
                <tr height="68" v-for="(item, i) in logs.result" :key="i">
                  <td>{{ item }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
        </v-card>
      </div>
      <v-dialog v-model="deleteModal" max-width="536">
        <v-card class="pa-md-10 px-sm-5 py-sm-10 py-5 px-4 text-center">
          <div class="text-h5 font-weight-bold">Delete provider</div>
          <div class="mt-3 opasity--text">Do you really want to delete the provider {{ data.name }}</div>
          <div class="mt-7">
            <v-btn width="120" height="48" class="mr-4 white--text" color="gray" @click="deleteModal = false">no</v-btn>
            <v-btn width="120" height="48" color="secondary" @click="deleteProvider">yes</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    data: {
      name: { required },
      login: { required },
      password: { required },
      host: { required },
      port: { required },
      limits: {
        inbound_window: { required },
        outbound_window: { required },
        max_binds: { required },
        inbound_rate_limit: { required },
        outbound_rate_limit: { required },
      },
    },
  },
  data() {
    return {
      load: true,
      deleteModal: false,
      show: false,
      headersBinds: [
        { text: 'Platform IP/Port', value: 'client_addr', sortable: false },
        { text: 'Provider IP/Port', value: 'gateway_addr', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Uptime', value: 'uptime', sortable: false },
        { text: 'Sent/Received', value: 'sent', sortable: false },
        { text: 'Rate, m/s', align: 'center', value: 'send_rate', sortable: false },
      ],
      logLevel: 'info',
      logLevelList: [
        { name: 'debug', title: 'Debug' },
        { name: 'info', title: 'Info' },
        { name: 'warn', title: 'Warn' },
        { name: 'error', title: 'Error' },
      ],
      error: null,
    };
  },
  mounted() {
    this.getData();
    this.getLogs();
    this.$store.dispatch('getProviderBinds');
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({ message: 'Provider has been updated', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'delete') {
        this.$notify({ message: 'Provider has been deleted', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'copy') {
        this.$notify({ message: 'Logs were copied to your clipboard', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'error') {
        this.$notify({ message: 'Error', type: 'warning', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    async getData() {
      await this.$store.dispatch('getProvider').finally(() => {
        this.load = false;
      });
    },
    async getLogs() {
      await this.$store.dispatch('getProviderLogs', this.logLevel);
    },
    async update(action) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.error = null;
        const data = new Object();
        data.name = this.data.name;
        data.login = this.data.login;
        data.password = this.data.password;
        data.enabled = this.data.enabled;
        data.host = this.data.host;
        data.port = +this.data.port;
        data.tls = this.data.tls;
        data.tls_validation = this.data.tls_validation;
        data.log_level = this.data.log_level;
        data.apply_now = action;
        data.limits.inbound_window = this.data.limits.inbound_window;
        data.limits.outbound_window = this.data.limits.outbound_window;
        data.limits.max_binds = this.data.limits.max_binds;
        data.limits.inbound_rate_limit = this.data.limits.inbound_rate_limit;
        data.limits.outbound_rate_limit = this.data.limits.outbound_rate_limit;
        await this.$store
          .dispatch('updateProvider', data)
          .then(() => {
            if (action) {
              this.notifi('update');
            }
          })
          .catch((e) => {
            this.error = e.response.data.error[0];
            this.notifi();
          });
      }
    },
    async deleteProvider() {
      await this.$store.dispatch('deleteProvider').then(() => {
        this.notifi('delete');
        this.$router.push('/providers');
      });
    },
    copy() {
      const string = this.logs.result.join('\n');
      this.$copyText(string).then(() => {
        this.notifi('copy');
      });
    },
  },
  watch: {
    data() {
      this.logLevel = this.data.log_level;
    },
  },
  computed: {
    data() {
      return this.$store.getters.provider;
    },
    binds() {
      return this.$store.getters.providerBinds;
    },
    logs() {
      return this.$store.getters.providerLogs;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.data.name.$dirty) {
        return errors;
      }
      !this.$v.data.name.required && errors.push('Please enter provider name');
      this.error == 'name__invalid' && errors.push('Provided name is not valid');
      this.error == 'name__already_exists' && errors.push('Provided name is already exist. Please use another one');
      return errors;
    },
    loginErrors() {
      const errors = [];
      if (!this.$v.data.login.$dirty) {
        return errors;
      }
      !this.$v.data.login.required && errors.push('Please enter provider login');
      this.error == 'login__invalid' && errors.push('Provided login is not valid');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.data.password.$dirty) {
        return errors;
      }
      !this.$v.data.password.required && errors.push('Please enter provider password');
      this.error == 'password__invalid' && errors.push('Provided password is not valid');
      return errors;
    },
    hostErrors() {
      const errors = [];
      if (!this.$v.data.host.$dirty) {
        return errors;
      }
      !this.$v.data.host.required && errors.push('Please enter provider host');
      this.error == 'host__invalid' && errors.push('Provided host is not valid');
      return errors;
    },
    portErrors() {
      const errors = [];
      if (!this.$v.data.port.$dirty) {
        return errors;
      }
      !this.$v.data.port.required && errors.push('Please enter provider port');
      this.error == 'port__invalid' && errors.push('Provided port is not valid');
      return errors;
    },
    inWinErrors() {
      const errors = [];
      if (!this.$v.data.limits.inbound_window.$dirty) {
        return errors;
      }
      !this.$v.data.limits.inbound_window.required && errors.push('Please enter limits inbound window value');
      this.error == 'limits__inbound_window__not_valid' && errors.push('Provided limits inbound window is not valid');
      return errors;
    },
    outWinErrors() {
      const errors = [];
      if (!this.$v.data.limits.outbound_window.$dirty) {
        return errors;
      }
      !this.$v.data.limits.outbound_window.required && errors.push('Please enter limits outbound window value');
      this.error == 'limits__outbound_window__not_valid' && errors.push('Provided limits outbound window is not valid');
      return errors;
    },
    maxBindsErrors() {
      const errors = [];
      if (!this.$v.data.limits.max_binds.$dirty) {
        return errors;
      }
      !this.$v.data.limits.max_binds.required && errors.push('Please enter max binds value');
      this.error == 'limits__max_binds_count__not_valid' && errors.push('Provided max binds is not valid');
      return errors;
    },
    inRateErrors() {
      const errors = [];
      if (!this.$v.data.limits.inbound_rate_limit.$dirty) {
        return errors;
      }
      !this.$v.data.limits.inbound_rate_limit.required && errors.push('Please enter limits inbound rate value');
      this.error == 'limits__inbound_rate_limit__not_valid' && errors.push('Provided limits inbound rate is not valid');
      return errors;
    },
    outRateErrors() {
      const errors = [];
      if (!this.$v.data.limits.outbound_rate_limit.$dirty) {
        return errors;
      }
      !this.$v.data.limits.outbound_rate_limit.required && errors.push('Please enter limits outbound rate value');
      this.error == 'limits__outbound_rate_limit__not_valid' && errors.push('Provided limits outbound rate is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setProvider', { limits: {} });
    this.$store.dispatch('setProviderBinds', { binds: [] });
    this.$store.dispatch('setProviderLogs', { result: [] });
  },
};
</script>

<style lang="scss" scoped>
.empty {
  background: black;
  width: 100%;
  height: 260px;
}
.status {
  height: 26px;
  border-radius: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  padding: 0 16px;
  color: white;
}
</style>
